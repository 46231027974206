import React, {   useState } from "react";
import { Redirect, Switch } from "react-router-dom";
import Dashboard from "pages/Dashboard/dashboard";
import Route from "./Route";
import PageNotFound from "components/404";
import SSOLogin from "pages/SSOLogin/SSOLogin";
import Landing from "pages/SSOLogin/Landing";
import { useDispatch, useSelector } from "react-redux";
import { decryptData } from "common/utils/cryptoJs";
import store from "app/store";
import { setAuthStatus } from "app/authReducer";
import Accounts from "pages/ProfileInfomation/Accounts";

const  Contentmanagement  = React.lazy(()=>import("pages/Contentmanagement/contentmanagement"));
const  Assetmanagement  = React.lazy(()=>import("pages/Assetmanagement/assetmanagement"));
const  Addcontent2 = React.lazy(()=>import("pages/Addcontent2/addcontent"));
const Viewcontent2 =     React.lazy(()=>import("pages/Viewcontent2/viewcontent"));
const Viewasset  =       React.lazy(()=>import("pages/Viewasset/viewasset"));
const Viewparticipant = React.lazy(()=>import("pages/Viewparticipant/viewparticipant"));
const Viewcareplan    = React.lazy(()=>import("pages/Viewcareplan/Viewcareplan"));
const Editasset       = React.lazy(()=>import("pages/Editasset/editasset"));
const Careplan     = React.lazy(()=>import("pages/Careplan/careplan"));
const Updatevendor = React.lazy(()=>import("pages/Updatevendor/updatevendor"));
const Editcontent2 = React.lazy(()=>import("pages/Editcontent2/editcontent"));
const Addasset     = React.lazy(()=>import("pages/Addasset/addasset"));
const Addassessment = React.lazy(()=>import("pages/Addassessment/addassessment"));
const AddVendor     = React.lazy(()=>import("pages/Addvendor/addvendor"));
const AddCareplan   = React.lazy(()=>import("pages/Addcareplan/addcareplan"));
const Viewvendor    = React.lazy(()=>import("pages/Viewvendor/viewvendor"));
const VendorManagement      = React.lazy(()=>import("pages/Vendormanagement/vendormanagement"));
const ParticipantManagement = React.lazy(()=>import("pages/Participants/participants"));
const ContentSync           = React.lazy(()=>import("pages/ContentSync/contentsync"));
const VendorSync            = React.lazy(()=>import("pages/VendorSync/vendorsync"));
const SelectTenant          = React.lazy(()=>import("pages/SelectTenant/SelectTenant"));
const AssessmentManagement  = React.lazy(()=>import("pages/Assessmentmanagement/assessmentmanagement"));
const UserManagement        = React.lazy(()=>import("pages/Usermanagement/usermanagement"));
//const RoleManagement        = React.lazy(()=>import("pages/RoleManagement/rolemanagement"));
const InactiveUser          = React.lazy(()=>import("pages/InactiveUser/InactiveUserPage"));
const Editcareplan          = React.lazy(()=>import("pages/Editcareplan/editcareplan"));
const UserView              = React.lazy(()=>import("pages/UserView/userview"));
const UserEdit              = React.lazy(()=>import("pages/UserEdit/useredit"));
const ViewAssessment        = React.lazy(()=>import("pages/ViewAssessment/viewAssessment"));
//const Addrules              = React.lazy(()=>import("pages/Addrules/addrules"));
const EditAssessment        = React.lazy(()=>import("pages/EditAssessment/editassessment"));
const Viewvendorsync        = React.lazy(()=>import("pages/ViewVendorSync/viewVendorSync"));
const Viewcontentsync       = React.lazy(()=>import("pages/ViewContentSync/viewContentSync"));
const AuditLogs             = React.lazy(()=>import("pages/AuditLog/auditLog"));
const ViewAuditLog          = React.lazy(()=>import("pages/ViewAuditLog/viewAuditLog"));
const ReportDashboard       = React.lazy(()=>import("pages/Reportdashboard/reportdashboard"));
const VendorReportDetail    = React.lazy(()=>import("pages/VendorDetailReport/vendorDetailsReport"));
//const VendorRegistration    = React.lazy(()=>import("pages/VendorRegistrationReCaptcha/vendorRegistrationReCaptcha"));
const UserDetailReport      = React.lazy(()=>import("pages/UserDetailReport/userDetailsReport"));
const VendorInActive        = React.lazy(()=>import("pages/VendorInActive/vendorInActivePage"));
const ParticipantReport     = React.lazy(()=>import("pages/ParticipantDetailReport/participantDetailsReport"));
const VendorActivity        = React.lazy(()=> import("pages/VendorActivityReport/vendorActivity"))
const Feedback              = React.lazy(()=> import("pages/Feedback/feedback"));
const ViewFeedback          = React.lazy(()=> import("pages/ViewFeedback/viewFeedback"));
const HelpOrFaq             = React.lazy(()=> import("pages/HelpOrFaq/helpIcons"));
const BatchUpdate           = React.lazy(()=> import("pages/BatchUpdate/batchUpdates"));
const BatchUpdateView       = React.lazy(()=> import("pages/BatchUpdateView/batchUpdateview"));
const BrokenLinks           = React.lazy(()=> import("pages/BrokenLinks/brokenlinks"));
const BrokenLinksView       = React.lazy(()=> import("pages/BrokenLinksView/brokenLinkView"));

export default function Routes() {
  const { ssoAccessToken } = useSelector((state: any) => {
    return state.user
  })
 const dispatch = useDispatch();
 const [carePlanAccessToken] = useState(() => {
   return localStorage.getItem("carePlanAccessToken")
 })
  const [actions] = useState(() => {
    const action = localStorage.getItem("actions");
    if (action !== null) {
      const salt = process.env.REACT_APP_CLIENT_SALT;
      const decryptedDATA = decryptData(action, salt);
      if (decryptedDATA !== null && decryptedDATA !== undefined)
        return decryptedDATA;
      else {
        window.userManager.signoutRedirect();
        dispatch(setAuthStatus({ isAuthenticated: false }));
        store.dispatch({ type: "LOGGED_OUT" });
        localStorage.removeItem("actions");
        localStorage.removeItem("carePlanAccessToken");
        localStorage.removeItem("carePlanRefreshToken");
        localStorage.removeItem("carePlanPermission");
        localStorage.removeItem("carePlanUserInfo");
        return;
      }
    }
    return undefined;
  })


  return (
    <React.Suspense fallback={  <div
      className="aui-loader"
      role="alert"
      aria-live="assertive"
      aria-label="Page is loading"
    />}>
   <Switch>
        {/* for login layout <Route path="/login" exact title={"American Heart Association"} component={Home as any} hideHeader/> */}
        {actions?.dashboard && <Route path="/dashboard" exact title={"Dashboard"} component={Dashboard as any}  protectedRoute/>}
        {actions?.content && <Route path="/contents" exact title={"Content Management"} component={(props)=><Contentmanagement {...props}></Contentmanagement>}  /> }
        {actions?.content && actions.content?.actions?.add && <Route path="/contents/add" exact title={"Add Content"} component={(props)=> <Addcontent2 {...props}/> }  />}
        {actions?.content && actions.content?.actions?.view  && <Route path="/contents/view/:id" exact title ={"View Content"} component={(props)=><Viewcontent2 {...props}/> }  />}
        {actions?.content && actions?.content?.actions?.edit  && <Route path="/contents/edit/:id" title= {"Edit Content"} exact component={(props)=><Editcontent2 {...props}/>}  />}
        
        {actions?.asset &&<Route path="/assets" exact title={"Asset Management"} component={ (props)=><Assetmanagement {...props}/>}  />   }     
        {actions?.asset &&  actions?.asset?.actions?.add && <Route path="/assets/add" exact title={"Add Asset"} component={      (props)=><Addasset  {...props} />}  />}
        {actions?.asset && actions?.asset?.actions?.view && <Route path="/assets/view/:id" exact title={"View Asset"} component={(props)=><Viewasset {...props} />}  />}
        {actions?.asset && actions?.asset?.actions?.edit && <Route path="/assets/edit/:id" title={"Edit Asset"} exact component={(props)=><Editasset {...props} />}  />}
      
        {actions?.vendor &&  <Route path="/vendors" exact title={"Vendor Management"} component={(props) => <VendorManagement {...props}/>}  />}
        {actions?.vendor &&  actions?.vendor?.actions?.add &&<Route path="/vendors/add" exact title={"Add Vendor"} component={(props)=><AddVendor {...props}/>}  />}
        {actions?.participants && <Route path="/vendors/participants" exact title={"All Participants"} component={(props) =><ParticipantManagement {...props}/>}/>}
        {actions?.vendor &&  actions?.vendor?.actions?.view && <Route path="/vendors/view/:id" exact title={"View Vendor"} component={(props)=><Viewvendor {...props}/>}  />}
        {actions?.vendor &&  actions?.vendor?.actions?.edit && <Route path="/vendors/edit/:id" exact title={"Edit Vendor"} component={(props)=><Updatevendor {...props}/>}  />}
        {actions?.participants &&  actions?.participants?.actions?.view && <Route path="/vendors/participants/view/:id" exact title={"View Participant"} component={(props)=><Viewparticipant {...props}/>}  />}
        

        {actions?.careplan &&    <Route path="/careplans" exact title={"CarePlan Management"} component={(props)=><Careplan {...props}/>}  />}
        {actions?.careplan &&  actions?.careplan?.actions?.add && <Route path="/careplans/add" exact title={"Add CarePlan"} component={       (props)=><AddCareplan  {...props}/>}  />}
        {actions?.careplan &&  actions?.careplan?.actions?.edit && <Route path="/careplans/edit/:id" exact title={"Edit CarePlan"} component={(props)=><Editcareplan {...props}/>}  />}
        {actions?.careplan &&  actions?.careplan?.actions?.view && <Route path="/careplans/view/:id" exact title={"View CarePlan"} component={(props)=><Viewcareplan {...props}/>}  />}
       

        {actions?.users && <Route path="/users" exact title={"User Management"} component={(props)=><UserManagement {...props}/>}/> }
        {actions?.users &&  actions?.users?.actions?.edit && <Route path="/users/edit/:id" exact title={"Edit User"} component={(props)=><UserEdit {...props}/>}  />}
        {actions?.users &&  actions?.users?.actions?.view &&<Route path="/users/view/:id" exact title={"View User"} component={ (props)=><UserView {...props}/>}  />}
      
        
        {/* {actions?.roles && <Route path="/roles" exact title={"Roles Management"} component={(props) =><RoleManagement {...props} /> }/> }
        {actions?.roles &&  actions?.roles?.actions?.add && <Route path="/users/addrules" exact title={"Add Rules"} component={(props)=><Addrules/>}/>} */}
       
        {actions?.assessment  &&<Route path="/assessments" exact title={"Assessment Management"} component={(props)=><AssessmentManagement {...props}/>}  />}
         {actions?.assessment &&  actions?.assessment?.actions?.add &&<Route path="/assessments/add" exact title={"Add Assessment"}         component={(props)=><Addassessment {...props}/>}  />}
         {actions?.assessment &&  actions?.assessment?.actions?.view && <Route path="/assessments/view/:id" exact title={"View Assessment"} component={(props)=><ViewAssessment {...props}/>}  />}
         {actions?.assessment &&  actions?.assessment?.actions?.edit && <Route path="/assessments/edit/:id" exact title={"Edit Assessment"} component={(props)=><EditAssessment {...props}/>}  />}

         {actions?.reports &&  <Route path="/reports" exact title={"Report Dashboard"} component={(props)=><ReportDashboard {...props}/>}/>  }
         {actions?.vendordetails &&  <Route path="/reports/vendordetails" exact title={"Vendor Activated/Inactivated"} component={(props)=><VendorReportDetail {...props}/>}/>  }
         {actions?.vendorSync && <Route path="/reports/vendorSync" exact title={"Vendor Sync"}    component={(props)=><VendorSync  {...props}/>}/>}  
         {actions?.contentSync && <Route path="/reports/contentSync" exact title={"Content Sync"} component={(props)=><ContentSync {...props}/>}/> }
         {actions?.auditLog && <Route path="/reports/auditLog" exact title={"Audit Log"}          component={(props)=><AuditLogs   {...props}/>}/> }
         {actions?.contentSync &&  actions?.contentSync?.actions?.view && <Route path="/reports/contentSync/view/:id" exact title={"View Content Sync"} component={(props)=><Viewcontentsync {...props} />}/> }
         {actions?.vendorSync &&  actions?.vendorSync?.actions?.view && <Route path="/reports/vendorSync/view/:id" exact title={"View Vendor Sync"}     component={(props)=><Viewvendorsync  {...props} />}/> }
         {actions?.auditLog &&  actions?.auditLog?.actions?.view && <Route path="/reports/auditLog/view/:id" exact title={"View Audit Log"}             component={(props)=><ViewAuditLog    {...props} />}/> }
         {actions?.userDetail &&  <Route path="/reports/userDetail" exact title={"User Activated/Inactivated"} component={(props)=><UserDetailReport {...props}/>}/>  }
         {actions?.participantDetail &&  <Route path="/reports/participantDetail" exact title={"Participants"} component={(props)=><ParticipantReport {...props}/>}/>  }
         {actions?.vendorActivity && <Route path="/reports/vendorActivity" exact title={"Vendor Activity Report"} component={(props) => <VendorActivity {...props} />} />}
         {carePlanAccessToken && <Route path="/profile" exact title={"Profile"} component={(props) => <Accounts {...props}/>} /> }
         {actions?.feedback  && <Route path="/feedback" exact title={"Feedback"} component={(props) => <Feedback {...props}/>} /> }

         {actions?.feedback &&  actions?.feedback?.actions?.view && <Route path="/feedback/view/:id" exact title={"ViewFeedback"} component={(props)=><ViewFeedback {...props}/> } />}

         
         {actions?.batchupdate  && <Route path="/batchupdate" exact title={"BatchUpdate"} component={(props) => <BatchUpdate {...props}/>} /> }
         {actions?.batchupdate  && actions?.batchupdate?.actions?.view && <Route path="/batchupdate/view/:id" exact title={"View BatchUpdate"} component={(props) => <BatchUpdateView {...props}/>} /> }
         {actions?.brokenLinks  && <Route path="/brokenLinks" exact title={"BrokenLinks"} component={(props) => <BrokenLinks {...props}/>} /> }
         {actions?.brokenLinks  && actions?.brokenLinks?.actions?.view && <Route path="/brokenLinks/view/:id" exact title={"View BrokenLinks"} component={(props) => <BrokenLinksView {...props}/>} /> }


        <Route path="/helpOrFAQ" exact title={"HelpIcons/FAQ"} component={HelpOrFaq as any} />     
        {/* <Route path="/vendorRegistration" exact title={"VendorRegistration"} component={VendorRegistration as any}  hideHeader/> */}
        <Route path="/vendorInActive" exact title={"Vendor Inactive"} component={VendorInActive as any} hideHeader/>
        <Route path="/dashboard" exact title={"Dashboard"} component={Dashboard as any}  protectedRoute/>
        <Route path="/landing" exact title={"Landing Page"} component={Landing as any} />
        <Route path="/inactive" exact title={"Inactive User"} component={InactiveUser as any} />
        <Route path="/SelectTenant" exact title={"American Heart Association"} component={SelectTenant as any} hideHeader/>
        <Route path="/" exact title={"American Heart Association"} component={SSOLogin as any} hideHeader/>
        {actions && <Route path="*" title={"404"} component={PageNotFound}/> }
         <Redirect to="/"/>
    </Switch>

    </React.Suspense>
  );
}
