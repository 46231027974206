/* eslint-disable no-debugger, no-console */
import { setAuthStatus } from "app/authReducer";
import store from "app/store";
import ContactUsChild from "pages/ContactUs/contactUs";
import { decryptData, encryptData } from "common/utils/cryptoJs";
import { logger } from "common/utils/logger.utils";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import CONSTANTS from "common/constants";
import { SSOLoginWrapper } from "./styled";
import { useHistory } from 'react-router-dom';
import {
  GoogleReCaptchaProvider
} from "react-google-recaptcha-v3";
export interface DialogBoxProperty {
  message: string;
  confirmHandler: Function;
}

const SSOLogin = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [on, setOn] = useState(false);

  useEffect(() => {
    const careplanPersmission = localStorage.getItem("carePlanPermission");
    let permission: any = {};
    if (careplanPersmission !== null && careplanPersmission !== undefined) {
      const salt = process.env.REACT_APP_CLIENT_SALT;
      permission = decryptData(careplanPersmission, salt);
    }

    if (permission !== null) {
      const accessToken = localStorage.getItem("carePlanAccessToken");
      //If already login then naviagte to dashboard immediately without showing login page
      if (Object.keys(permission).length > 0) {
        const landingMenu = permission?.menus?.filter((menu: any) => menu.landingPage);
        permission
          ? landingMenu?.length
            ? props.history.replace({
              pathname: landingMenu[0]?.url,
              state: landingMenu[0].actions,
            })
            : props.history.replace({
              pathname: permission.menus
                ? permission.menus[0]?.url
                : "/dashboard",
              state: permission.menus
                ? permission.menus[0].actions
                : { view: true },
            })
          : props.history.replace("/inactive");
      } else if (accessToken) {
        props.history.replace("inactive");
      }
    } else {
      window.userManager.signoutRedirect();
      dispatch(setAuthStatus({ isAuthenticated: false }));
      store.dispatch({ type: "LOGGED_OUT" });
      localStorage.removeItem("actions");
      localStorage.removeItem("carePlanAccessToken");
      localStorage.removeItem("carePlanRefreshToken");
      localStorage.removeItem("carePlanPermission");
    }

  }, [])
  const handlecontactus = (event: any) => {
    document.querySelector('.contact-us-wrap')?.classList.toggle('d-none');
    if (document.querySelector('.contact-us-wrap')?.classList.contains("d-none")) {
      event.currentTarget.setAttribute("aria-pressed", "false");
    }
    else {
      event.currentTarget.setAttribute("aria-pressed", "true");
    }
  }
  const keydownhandlecontactus = (event: any) => {
    if (event.keyCode === CONSTANTS.KEY_ENTER || event.keyCode === CONSTANTS.KEY_SPACEBAR) {
      event.currentTarget.click();
    }
  }
  const redirectToSSO = () => {
    window.userManager
      .signinRedirect({ state: { tenantId: 1 } })
      .then(function (data: any) {
        logger("signinRedirect ok..................", data);
      })
      .catch(function (err: any) {
        logger("signinRedirect error:", err);
      });
  };

  return (
    <SSOLoginWrapper>
      <div className="row no-gutters l-block login-wrapper">
      <div
          className={process.env.REACT_APP_MY_ENV != "prod"?"align-middle col-md-12 col-lg-12 p-0 l-block-bg left-content position-relative":"align-middle col-md-12 col-lg-12 p-0 l-block-bg left-content position-relative prodbranch_container"}
          role="presentation"
        >
          <div className="imgone background-none position-relative">
            <div className="imgs-left imgone position-absolute" role="img" aria-label="doctor and patient smiling"></div>
            <div className="right-content col-12 col-lg-6 d-flex l-container">
              <div className="w-100 align-self-center l-form">
                <div className="col-padin-mob w-100 pl-0 pr-0 ml-0 mr-0">
                  <div className="login-contents">
                    <h1 className="position-relative">Intelligent Health Solutions</h1>
                    <div className="login-para mb-0">
                      The Intelligent Health Solutions include digital CarePlans and content developed by the American Heart Association, Center for Health Technology and Innovation. These CarePlans are a translation of American Heart Association guidelines and scientific statements and are designed to build knowledge and skills necessary to manage heart health.
                    </div>
                    {process.env.REACT_APP_MY_ENV != "prod" && process.env.REACT_APP_VENDOR_REGISTRATION != "false" &&(<button
                      type="submit" onClick={() => history.push('/vendorRegistration')}
                      className="sso-btn submit-btn btn px-3 mx-auto mx-lg-0 d-block mb-0 float-left"
                    >
                      Vendor Registration 
                    </button>
                    )}
                    <button
                      type="submit" onClick={redirectToSSO}
                      className="sso-btn submit-btn btn px-3 mx-auto mx-lg-0 d-block mb-0 float-right"
                    >
                      Sign In | Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {process.env.REACT_APP_MY_ENV != "prod" && (
            <div className="contact-us-icon position-absolute" aria-pressed="false" role="button" aria-label="contact us button link" onClick={(event: any) => { handlecontactus(event); setOn(!on) }} onKeyDown={keydownhandlecontactus} tabIndex={0}>
              <button type="button" className="btn btn-sm btn-round btn-secondary position-absolute pl-0 pr-0 d-none">Contact us</button>
            </div>
            )}
            {localStorage.getItem("carePlanAccessToken") == undefined && (
              on ?
                <GoogleReCaptchaProvider
                  language="en-US"
                  reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY??""}
                >
                  <ContactUsChild switch={setOn}></ContactUsChild>
                </GoogleReCaptchaProvider>
                : false
            )}
          </div>
        </div>
      </div>
    </SSOLoginWrapper>
  );
};

export default SSOLogin;
